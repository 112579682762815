import { VStack, Image, getCdnImageSrc, responsive } from "goi_common";
import { Box } from "@chakra-ui/react";

export default function HighlightWordSection() {
  return (
    <>
      <Box textAlign="center" className="subtitle_21_b md_head_42_b">
        우리 가족의 마지막 순간인 장례,
        <br />
        투명하고 정직한 서비스를 고집합니다.
      </Box>
      <VStack
        alignItems="center"
        css={responsive({
          padding: { base: "33px 0", md: "76px 0" },
        })}
      >
        <Image
          src={getCdnImageSrc(`direct/home/highlightbar.png`)}
          height="auto"
          alt=""
          css={responsive({
            width: { base: "350px", md: "900px" },
          })}
        />
      </VStack>
    </>
  );
}
