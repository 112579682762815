import { MAX_WIDTH } from "@/constants/components";
import { Box } from "@chakra-ui/react";
import {
  colors,
  getCdnImageSrc,
  HStack,
  Image,
  responsive,
  useResponsive,
  VStack,
} from "goi_common";
import { ReactNode } from "react";

export default function WhyNowSangjoSection() {
  const { isBiggerThanLg } = useResponsive();
  return (
    <VStack width="100%" alignItems="center" background={colors.brown50}>
      <VStack
        maxWidth={`${MAX_WIDTH}px`}
        width="100%"
        css={responsive({
          padding: { base: "60px 24px 80px", lg: "180px 24px" },
        })}
      >
        <Box className="gray900 head_24_b lg_head_42_b" textAlign="center">
          꼭 지금 가입해야 할까요?
        </Box>
        <HStack margin="80px 0 0" justifyContent="space-between">
          <VStack gap="80px">
            <NowSangjoItem
              num={1}
              title={
                <>
                  <b css={{ color: colors.brown550 }}>2명 중 1명</b>은 상조에
                  가입했어요
                </>
              }
              description={
                "40~50대 2명 중 1명은 상조에 가입했어요.\n2023년 기준 상조 가입자 수는 850만 명, 선수금은 9.5조를\n넘을만큼 상조 가입은 필수로 자리잡았습니다."
              }
            />
            <NowSangjoItem
              num={2}
              title={
                <>
                  이 기회를 놓치면{" "}
                  <b css={{ color: colors.brown550 }}>가격이 비싸져요</b>
                </>
              }
              description={
                "매년 장례 비용은 3~5%씩 비싸집니다.\n지금 월 100원만 내면 10년 뒤 장례 비용을 300만 원 이상 절감\n하는 효과가 있어요. 지금 가격을 평생 보장받아 보세요."
              }
            />
            <NowSangjoItem
              num={3}
              title={
                <>
                  가격 거품으로 인한{" "}
                  <b css={{ color: colors.brown550 }}>피해를 막을 수 있어요</b>
                </>
              }
              description={
                "갑작스럽게 장례를 치르면, 생각지 못한 추가 장례비용들이 발생합니다.\n고이는 가격 정찰제, 노잣돈 및 수고비 요구 금지 등의 정책으로\n투명하고 정직한 장례 서비스를 약속드려요"
              }
            />
          </VStack>
          {isBiggerThanLg && (
            <VStack>
              <Image
                src={getCdnImageSrc(`direct/home/now-pc.png`)}
                alt=""
                width="480px"
              />
            </VStack>
          )}
        </HStack>
      </VStack>
    </VStack>
  );
}

function NowSangjoItem({
  num,
  title,
  description,
}: {
  num: number;
  title: ReactNode;
  description: string;
}) {
  return (
    <HStack gap="16px">
      <VStack
        css={responsive({
          flexShrink: 1,
          width: { base: "70px", lg: "108px" },
        })}
      >
        <Image src={getCdnImageSrc(`direct/home/now-2-${num}.png`)} alt="" />
      </VStack>
      <VStack
        gap="12px"
        css={responsive({
          width: { base: "100%", lg: "500px" },
        })}
      >
        <Box className="subtitle_21_b lg_head_28_b" color={colors.brown400}>
          0{num} {title}
        </Box>
        <Box
          className="gray900 body_16_r lg_body_18_m"
          css={responsive({
            whiteSpace: { base: "initial", lg: "pre-line" },
          })}
        >
          {description}
        </Box>
      </VStack>
    </HStack>
  );
}
