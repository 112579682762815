import {
  VStack,
  Button,
  Image,
  getCdnImageSrc,
  customEvent,
  colors,
  responsive,
  NextLink,
  Spacing,
  useResponsive,
  kakaoPixel,
  pixelCustomEvent,
  PixelEventName,
  daangnPixelTrack,
} from "goi_common";
import { Box } from "@chakra-ui/react";
import { naverConversionEvent } from "@/utils/naverPageView";

export default function HeroSection({
  district,
}: {
  district?: {
    title: string;
    description: string;
    eventPath: "home_honam" | "home_dggb" | "home_female";
  };
}) {
  const { isBiggerThanMd } = useResponsive();

  return (
    <VStack
      background="#FDECDD"
      width="100%"
      overflow="hidden"
      alignItems="center"
      position="relative"
      css={responsive({
        padding: { base: "20px 0 36px", md: "0 0 68px" },
      })}
    >
      <Box
        marginBottom="-30px"
        css={responsive({
          width: { base: "260px", md: "400px" },
          marginRight: { base: "45px", md: "65px" },
        })}
      >
        <Image
          src={getCdnImageSrc(`direct/home/100won.png`)}
          width="100%"
          height="auto"
          alt=""
        />
      </Box>
      <Box textAlign="center" className="orange650 subtitle_16_b md_head_24_b">
        고이 PRIME
      </Box>
      <Box
        textAlign="center"
        css={responsive({
          marginTop: "8px",
          fontSize: { base: "28px", md: "72px" },
          fontWeight: { base: 700, md: 800 },
          lineHeight: { base: "42px", md: "100px" },
        })}
        color={colors.brown650}
        whiteSpace="pre-line"
      >
        {district?.title || "매달 100원으로 장례 준비."}
      </Box>
      <Spacing gap={isBiggerThanMd ? 10 : 4} />
      <Box
        textAlign="center"
        className="md_head_32_sb subtitle_16_sb"
        color={colors.brown650}
      >
        {district?.description || "똑똑한 소비자를 위한 상조, 고이 프라임."}
      </Box>

      <NextLink href={"/registration/info/"}>
        <Button
          className="subtitle_18_sb md_head_32_sb"
          onClick={() => {
            customEvent({
              newGtm: {
                path: district?.eventPath || "home",
                trigger_and_target: "CTA",
              },
            });
            customEvent({
              newGtm: {
                path: district?.eventPath || "home",
                trigger_and_target: "hero_CTA-uv_registration",
              },
            });

            naverConversionEvent({ type: "view_product" });
            kakaoPixel()?.participation();
            daangnPixelTrack("ViewContent");
            pixelCustomEvent(PixelEventName.ViewContent);
          }}
          css={responsive({
            border: "1px solid white",
            borderRadius: "12px",
            background: colors.brown650,
            color: colors.white,
            padding: { base: "12px 28px", md: "21px 62px" },
            marginTop: { base: "24px", md: "48px" },
          })}
        >
          시작하기
        </Button>
      </NextLink>
    </VStack>
  );
}
