import { Box } from "@chakra-ui/react";
import { VStack, Image, getCdnImageSrc, colors, responsive } from "goi_common";

import { MAX_WIDTH } from "@/constants/components";

export default function QualitySection() {
  return (
    <VStack width="100%" alignItems="center" background={colors.warmGray100}>
      <VStack
        maxWidth={`${MAX_WIDTH}px`}
        width="100%"
        css={responsive({
          padding: { base: "0 24px", lg: "60px 24px" },
        })}
      >
        <Box
          display="flex"
          css={responsive({
            flexDirection: { base: "column", lg: "row" },
          })}
        >
          <VStack
            css={responsive({
              width: { base: "100%", lg: "50%" },
            })}
          >
            <Box
              className="gray900 head_24_eb lg_head_42_b"
              css={responsive({
                textAlign: { base: "center", lg: "left" },
              })}
            >
              거품은 없애고,
              <br />
              품질은 높였습니다.
            </Box>
            <Box
              className="gray600 subtitle_18_eb lg_head_28_m"
              css={responsive({
                padding: { base: "40px 0", lg: "16px 0 0" },
                textAlign: { base: "center", lg: "left" },
              })}
            >
              과도한 마케팅 비용, 하청 수수료를 없애고
              <br />더 높은 품질의 장례 용품에 투자했습니다.
            </Box>
          </VStack>
          <VStack
            overflow="hidden"
            borderRadius="8px"
            alignItems="center"
            background="white"
            css={responsive({
              width: { base: "100%", lg: "50%" },
            })}
          >
            <Image
              src={getCdnImageSrc("direct/home/quality-img.png")}
              alt=""
              css={responsive({
                width: { base: "100%", lg: "287px" },
              })}
            />
          </VStack>
        </Box>
      </VStack>
    </VStack>
  );
}
